<template>
    <v-dialog v-model="dialog" max-width="700">
        <v-card class="text-left">
            <v-card-title>
                <div class="subtitle-1 font-weight-light text-left ml-3 mt-2"> Ajout d'un Rapport journalier</div>
                <v-spacer />
                <v-icon aria-label="Close" @click="dialog = false" >
                    mdi-close
                </v-icon>
            </v-card-title>

            <v-card-text>
            </v-card-text>

            <v-card-actions>
                    <v-spacer />
                    <v-row>
                        <v-col cols="12" class="text-right" >
                            <el-button type="warning" round>Annuler</el-button>
                            <el-button type="primary" round>Enregistrer</el-button>
                        </v-col>
                    </v-row>
                </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import axios from 'axios';
    import moment from 'moment';
    import NProgress from 'nprogress';
    
    export default {

        props: ['dialog'],

        data() {

            return {

            }
        },

        methods: {

            async loadData()
            {
                NProgress.start();

                try {
                
                    const response = await axios.get('v1/xperf/setting/agence/get');

                    this.all_data = response.data;
                    this.loading = false;


                } 
                catch (error) {

                    this.loading = false;
                    this.$notify({
                        type: 'error',
                        title: "Echec de recuperation"
                    });
                }

                NProgress.done();

            },


        },

    }

</script>