<template>
    <div class="mt-3">
        <v-row class="m-3">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 margin-bottom"  v-if="agencesOption.length > 1">
                <label class="mb-2">{{titleChoice}}  </label>
                <div class="data-picker" style="width:100%">
                    <ui-select
                        :placeholder="titleChoice"
                        :options="agencesOption"
                        @change="loadSolde()"
                        v-model="agenceSelect"
                    ></ui-select>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 margin-bottom ml-2">
                <label class="mb-2">{{titleChoiceMonth}}  </label>
                <div class="data-picker" style="width:100%">
                    <ui-select
                        :placeholder="titleChoice"
                        :options="MoisOption"
                        @change="loadSolde()"
                        v-model="MoisSelect"
                    ></ui-select>
                </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 margin-bottom ml-2 mt-3"  v-if="permissions[0].includes('saisie')">
                <ui-button @click="addRapport()" class="p-2 m-2"><i class="el-icon-plus mr-2"></i> Ajout d'un rapport</ui-button>
            </div>
        </v-row>
        
        <div id="app">
            <el-button type="success" class="m-3 p-3" style="border-radius:0px; font-size:20px" disabled>Solde Caisse: {{soldeGlobal.Caisse}} Fcfa</el-button>
        </div>

        <div>
            <el-skeleton :rows="10" animated v-if="loading" />
            <el-table v-else :data="tableData.filter(data => !search || data.enc_vte.toLowerCase().includes(search.toLowerCase()))" style="width: 100%" >
                <el-table-column label="Date" prop="date" sortable></el-table-column>
                <el-table-column label="Encaissement vente" prop="enc_vte" sortable></el-table-column>
                <el-table-column label="Autre Encaissement" prop="enc_autre" sortable></el-table-column>
                <el-table-column label="Crédit m-1" prop="enc_cred_m1" sortable></el-table-column>
                <el-table-column label="Vente à crédit" prop="vte_cred" sortable></el-table-column>
                <el-table-column label="Dépense" prop="depense" sortable></el-table-column>
                <el-table-column label="Retour" prop="retour" sortable></el-table-column>
                <el-table-column align="right" fixed="right" width="250">
                <template slot="header" slot-scope="scope">
                    <el-input v-model="search" size="mini" placeholder="Type to search"/>
                </template>
                <template slot-scope="scope">
                    <el-button size="mini" type="success" v-if="scope.row.is_validate == '0' && permissions[0].includes('validation')" @click="showDialogValidate(scope.row)"><i class="el-icon-finished"></i></el-button>
                    <el-button size="mini" type="infos" @click="watchRow(scope.row)"><i class="el-icon-view" ></i></el-button>
                    <el-button size="mini" type="primary" v-if="permissions[0].includes('saisie') || permissions[0].includes('validation')"><i class="el-icon-edit" @click="editRow(scope.row)"></i></el-button>
                    <el-button size="mini" type="danger" v-if="permissions[0].includes('saisie') || permissions[0].includes('validation')"><i class="el-icon-delete"  @click="showDialogDelete(scope.row)"></i></el-button>
                </template>
                </el-table-column>
            </el-table>
        </div>


        <!-- Modal add op-->
        <ui-modal ref="modal1" title="Ajout d'un rapport" size="large">
            <div>
                <v-row>
                    <v-col cols="12" lg="6">
                        <div class="subtitle-10 font-weight-light"> Date</div>
                        <el-date-picker type="date" placeholder="Choisir le jour" v-model="form.date"></el-date-picker>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <ui-textbox floating-label label="Encaissement vente" v-model="form.enc_vte"></ui-textbox>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <ui-textbox floating-label label="Autre encaissement" v-model="form.enc_autre"></ui-textbox>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <ui-textbox floating-label label="Encaissement crédit mois précédent" v-model="form.enc_cred_m1"></ui-textbox>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <ui-textbox floating-label label="Vente à crédit" v-model="form.vte_cred"></ui-textbox>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <ui-textbox floating-label label="Retour" v-model="form.retour"></ui-textbox>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <ui-textbox floating-label label="Dépense" v-model="form.depense"></ui-textbox>
                    </v-col>
                    <v-col cols="12" lg="12">
                        <ui-textbox floating-label label="Observation" v-model="form.observ"></ui-textbox>
                    </v-col>
                </v-row>

                <ui-collapsible title="Enregistrement des versements" class="mt-2">
                    <v-row v-for="item in versements" :key="item.id">
                        <v-col cols="12" lg="4">
                            <ui-textbox floating-label :label="item.title" v-model="item.amount"></ui-textbox>
                        </v-col>
                        <v-col cols="12" lg="8">
                            <ui-textbox floating-label label="Numéro de confirmation" v-model="item.number"></ui-textbox>
                        </v-col>
                    </v-row>
                </ui-collapsible>

                <v-row>
                    <v-col cols="12" class="text-right" >
                         <v-btn color="primary" class="mr-2" style="border-radius:0px" @click="saveOperation()">
                            Enregistrer
                        </v-btn>
                        <v-btn color="warning" class="" style="border-radius:0px" @click="closeModal('modal1')">
                            Annuler
                        </v-btn>
                    </v-col>
                </v-row>
            
            </div>
        </ui-modal>

        <!-- Modal edit op-->
        <ui-modal ref="edit" title="Ajout d'un rapport" size="large">
            <div>
                <v-row>
                    <v-col cols="12" lg="6">
                        <div class="subtitle-10 font-weight-light"> Date</div>
                        <el-date-picker type="date" placeholder="Choisir le jour" v-model="form_edit.date"></el-date-picker>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <ui-textbox floating-label label="Encaissement vente" v-model="form_edit.enc_vte"></ui-textbox>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <ui-textbox floating-label label="Autre encaissement" v-model="form_edit.enc_autre"></ui-textbox>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <ui-textbox floating-label label="Encaissement crédit mois précédent" v-model="form_edit.enc_cred_m1"></ui-textbox>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <ui-textbox floating-label label="Vente à crédit" v-model="form_edit.vte_cred"></ui-textbox>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <ui-textbox floating-label label="Retour" v-model="form_edit.retour"></ui-textbox>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <ui-textbox floating-label label="Dépense" v-model="form_edit.depense"></ui-textbox>
                    </v-col>
                    <v-col cols="12" lg="12">
                        <ui-textbox floating-label label="Observation" v-model="form_edit.observ"></ui-textbox>
                    </v-col>
                </v-row>

                <ui-collapsible title="Modification des versements" class="mt-2">
                    <section v-for="item in versements" :key="item.id">
                        <v-row v-if="item.title">
                            <v-col cols="12" lg="4">
                                <ui-textbox floating-label :label="item.title" v-model="item.amount"></ui-textbox>
                            </v-col>
                            <v-col cols="12" lg="8">
                                <ui-textbox floating-label label="Numéro de confirmation" v-model="item.number"></ui-textbox>
                            </v-col>
                        </v-row>
                    </section>
                    
                </ui-collapsible>

                <v-row>
                    <v-col cols="12" class="text-right" >
                         <v-btn color="primary" class="mr-2" style="border-radius:0px" @click="UpdateOperation()">
                            Enregistrer
                        </v-btn>
                        <v-btn color="warning" class="" style="border-radius:0px" @click="closeModal('edit')">
                            Annuler
                        </v-btn>
                    </v-col>
                </v-row>
            
            </div>
        </ui-modal>

        <!-- Modal watch op -->
        <ui-modal ref="watch" title="Consultation d'un rapport" size="large">
            <div>
                <v-row>
                    <v-col cols="12" md="12" >
                        <v-text-field class="purple-input" v-model="form_watch.date" label="Date" disabled/>
                    </v-col>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form_watch.enc_vte" label="Encaissement vente" disabled/>
                    </v-col>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form_watch.enc_autre" label="Autre Encaissement" disabled/>
                    </v-col>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form_watch.enc_cred_m1" label="Encaissement Crédit m-1" disabled/>
                    </v-col>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form_watch.vte_cred" label="Vente à crédit" disabled/>
                    </v-col>
                     <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form_watch.retour" label="Retour" disabled/>
                    </v-col>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form_watch.depense" label="Dépense" disabled/>
                    </v-col>
                    <v-col cols="12" md="12" >
                        <v-text-field class="purple-input" v-model="form_watch.observ" label="Observation" disabled/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="12" >
                        <div class="subtitle-1 font-weight-light"> Liste des Versements effectuer </div>
                    </v-col>
                    <v-col cols="12" md="4" v-for="item in form_watch.banque" :key="item.title">
                        <v-text-field class="purple-input" v-model="item.amount" :label="item.banque" disabled/>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="text-right" >
                        <v-btn color="warning" class="" style="border-radius:0px" @click="closeModal('watch')">
                            Fermer
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </ui-modal>

    </div>
</template>


<script>


    import axios from 'axios';
    import moment from 'moment';
    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';

    import AddRapportComponent from '@/components/dashboard/Report/Etat/AddRapportComponent.vue';


    export default {
        
        components: {
            AddRapportComponent,
        },

        props: ['permissions'],

        data() {

            return{

                titleChoice: "Choisir l'agence",
                title: 'Historique des opérations',
                titleChoiceMonth: "Choisir le mois",
                dialog: false,
                agencesOption: [],
                MoisOption: [
                    {label: 'Janvier', value: 1},
                    {label: 'Février', value: 2},
                    {label: 'Mars', value: 3},
                    {label: 'Avril', value: 4},
                    {label: 'Mai', value: 5},
                    {label: 'Juin', value: 6},
                    {label: 'Juillet', value: 7},
                    {label: 'Aout', value: 8},
                    {label: 'Sèptembre', value: 9},
                    {label: 'Octobre', value: 10},
                    {label: 'Novembre', value: 11},
                    {label: 'Décembre', value: 12},
                ],
                show_site_filter: 0,
                agenceSelect: [],
                MoisSelect:[],
                items: [],

                soldeGlobal: [],
                soldeMonth: [],

                loadingInstance: null,

                form : {
                    id: null,
                    date: '',
                    enc_vte: 0,
                    enc_autre: 0,
                    enc_cred_m1: 0,
                    vte_cred: 0,
                    retour: 0,
                    depense : 0,
                    depense_all: '',
                    description: '',
                    observ: '',
                    week_number: 0,
                    month_number: 0,
                    month_name: '',
                    is_validate: 0,
                    compte: [],
                },

                // watch form
                form_watch : {},

                // edit

                form_edit : {},

                versements: [],
                caisse: {},

                // Notification
                loading: false,
                tableData: [],
                search: '',
            }
        },

        methods: {

            async loadSolde()
            {
                NProgress.start();
                this.loading = true;

                try {

                    let crm = {
                        agence_id: this.agenceSelect.value,
                        start : moment().month((this.MoisSelect.value-1)).startOf('month').format('YYYY-MM-DD'),
                        end : moment().month(this.MoisSelect.value).startOf('month').format('YYYY-MM-DD')
                    }
                
                    const response = await axios.post('v1/xperf/report/etat/getStoryAccount', crm);
                    var res_data = response.data.solde;
                    var res_versement = res_data.banque;
                    this.tableData = response.data.operation;

                    if (res_data) {
                        this.soldeGlobal = res_data.global;toLocaleString
                        this.soldeMonth = res_data.global;
                        var champ = Object.keys(this.soldeGlobal);

                        champ.forEach(element => {
                            this.soldeGlobal[element] = parseFloat(this.soldeGlobal[element]).toLocaleString();
                        });
                    }
                    this.versements = [];
                    res_versement.forEach(element => {
                        if (element.title != 'Caisse') {
                            let row = { compte_id: element.id, number: '', title: element.title, number: '', amount: 0, }
                            this.versements.push(row);
                        }
                        else{
                            this.caisse = { compte_id: element.id, number: '', title: element.title, number: '', amount: 0, }   
                        }
                    });
                    this.form.compte = [];
                } 
                catch (error) {

                }
                this.loading = false;
                NProgress.done();

            },

            openModal(ref) {
                this.$refs[ref].open();
            },

            openEdit(ref) {
                this.$refs[ref].open();
            },

            closeModal(ref) {
                this.$refs[ref].close();
                this.clearForm();
            },

            closeEdit(ref){
                this.$refs[ref].close();
                this.form_edit = {};
            },

            formatSite(){

                let All_site = JSON.parse(localStorage.getItem('site'));
                All_site.forEach(element => {
                    let el = {
                        label: element.nom,
                        value: element.id,
                    };
                    this.agencesOption.push(el);
                });
                this.agenceSelect = this.agencesOption[0];
            },

            setCurentMonth(){

                let m = (moment().month())+1;

                this.MoisOption.forEach(element => {
                    if (element.value == m) {
                        this.MoisSelect = element; 
                    }
                });
            },

            addRapport(){
                this.versements.forEach(element => {
                    element.amount = 0;
                });
                this.openModal('modal1');
            },

            watchRow(row){

                row['banque'] = [];
                row.comptes.forEach(element => {
                    this.versements.forEach(compte => {
                        if(element.compte_id == compte.compte_id){
                            let rows = {
                                banque : compte.title,
                                amount : element.amount
                            }
                        row.banque.push(rows);
                        }
                    });
                });

                this.form_watch = row;
                this.openModal('watch');

            },

            editRow(row){
                console.log(row);
                console.log(this.versements);
                this.versements.forEach(compte => {
                    row.comptes.forEach(element => {
                        if(element.compte_id == compte.compte_id){
                          compte.amount = element.amount;
                        }
                    });
                });

                this.form_edit = row;
                this.openEdit('edit');
            },

            saveOperation(){
                
                this.form.date = moment(new Date(this.form.date)).format('YYYY-MM-DD');
                this.form.month_number = this.MoisSelect.value;
                this.form.month_name = this.MoisSelect.label;
                this.caisse.amount = parseFloat(this.form.enc_vte) + parseFloat(this.form.enc_autre) + parseFloat(this.form.enc_cred_m1) - parseFloat(this.form.depense) - parseFloat(this.form.retour);
                this.form.enc_vte = parseFloat(this.form.enc_vte);
                this.form.enc_autre = parseFloat(this.form.enc_autre);
                this.form.enc_cred_m1 = parseFloat(this.form.enc_cred_m1);
                this.form.depense = parseFloat(this.form.depense);
                this.form.vte_cred = parseFloat(this.form.vte_cred);
                this.form.retour = parseFloat(this.form.retour);

                this.versements.forEach(element => {
                    if (element.amount > 0 ) {
                        element.amount = parseFloat(element.amount);
                        this.caisse.amount = parseFloat(this.caisse.amount) - element.amount;
                        this.form.compte.push(element);
                    }
                });
                
                this.form.compte.push(this.caisse);
                
                this.showDialogSave();
            },

            UpdateOperation(){
                this.form_edit.compte = [],
                this.form_edit.date = moment(new Date(this.form_edit.date)).format('YYYY-MM-DD');
                this.form_edit.month_number = this.MoisSelect.value;
                this.form_edit.month_name = this.MoisSelect.label;
                this.caisse.amount = parseFloat(this.form_edit.enc_vte) + parseFloat(this.form_edit.enc_autre) + parseFloat(this.form_edit.enc_cred_m1) - parseFloat(this.form_edit.depense) - parseFloat(this.form_edit.retour);
                this.form_edit.enc_vte = parseFloat(this.form_edit.enc_vte);
                this.form_edit.enc_autre = parseFloat(this.form_edit.enc_autre);
                this.form_edit.enc_cred_m1 = parseFloat(this.form_edit.enc_cred_m1);
                this.form_edit.depense = parseFloat(this.form_edit.depense);
                this.form_edit.vte_cred = parseFloat(this.form_edit.vte_cred);
                this.form_edit.retour = parseFloat(this.form_edit.retour);

                this.versements.forEach(element => {
                    if (element.amount > 0 ) {
                        
                        let op = {
                            compte_id: element.compte_id,
                            number: element.number,
                            amount: parseFloat(element.amount)
                        };
                        this.caisse.amount = parseFloat(this.caisse.amount) - op.amount;

                        this.form_edit.compte.push(op);
                    }
                });
                
                let caisse = {
                    compte_id: this.caisse.compte_id,
                    number: this.caisse.number,
                    amount: this.caisse.amount
                };

                this.form_edit.compte.push(caisse);
                this.showDialogUpdate();

            },

            showDialogSave(){
                this.$swal({
                title: 'Confirmer ?',
                text: "Vous êtes sur le point d'enregistrer le rapport",
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#EE9D06',
                confirmButtonText: 'Oui'
                }).then((result) => {
                    if (result.value) {
                        this.saveRapport();
                    }
                });
            },

            showDialogUpdate(){
                this.$swal({
                title: 'Confirmer ?',
                text: "Vous êtes sur le point de faire la mise à jour du rapport",
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#EE9D06',
                confirmButtonText: 'Oui'
                }).then((result) => {
                    if (result.value) {
                        this.updateRapport();
                    }
                });
            },

            showDialogValidate(row){

                this.$swal({
                title: 'Confirmer ?',
                text: "Vous êtes sur le point de valider le rapport",
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#47C20A',
                cancelButtonColor: '#EE9D06',
                confirmButtonText: 'Oui'
                }).then((result) => {
                if (result.value) {
                    this.validate(row);
                }
                });
            },

            showDialogDelete(row){
                this.$swal({
                title: 'Confirmer ?',
                text: "Vous êtes sur le point de supprimer le rapport",
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#47C20A',
                cancelButtonColor: '#EE9D06',
                confirmButtonText: 'Oui'
                }).then((result) => {
                    if (result.value) {
                        this.delete(row);
                    }
                });
            },

            async saveRapport() {

                NProgress.start();

                 try {

                    await axios.post('v1/xperf/report/etat/storeOperation', this.form);
                    this.loadSolde();
                    this.closeModal('modal1');
                    this.$swal(
                        'Enregistrer !',
                        'Raport enregistrer avec succès',
                        'success'
                    );

                } 
                catch (error) {
                    this.form.date = '',
                    this.form.compte = [],
                    this.$swal(
                        'Erreur !',
                        "Echec d'enregistrement, merci de vérifier votre rapport",
                        'error'
                    );
                }

                NProgress.done();
            },

            async updateRapport(){

                NProgress.start();
                try {

                    await axios.post('v1/xperf/report/etat/updateOpération', this.form_edit);
                    this.loadSolde();
                    this.closeEdit('edit');
                    this.$swal(
                        'Enregistrer !',
                        'Modification effectuer avec succès',
                        'success'
                    );
                } 
                catch (error) {
                    this.form_edit.date = '',
                    this.form_edit.compte = [],
                    this.$swal(
                        'Erreur !',
                        "Echec de modification, merci de vérifier votre rapport",
                        'error'
                    );
                }
                NProgress.done();
            },

            async validate(row){
                 NProgress.start();

                 let crm = { 
                    id: row.id,
                    is_validate : 1
                }
                try {
                    await axios.post('v1/xperf/report/etat/validateOperation', crm);
                    this.loadSolde();
                    this.$swal(
                        'Enregistrer !',
                        'Raport valider avec succès',
                        'success'
                    );

                } 
                catch (error) {
                    this.$swal(
                        'Erreur !',
                        "Echec de validation, merci de vérifier votre rapport",
                        'error'
                    );
                }
                NProgress.done();
            },

            async delete(row){
                NProgress.start();

                let crm = { 
                    id: row.id,
                }
                try {
                    await axios.post('v1/xperf/report/etat/deleteOperation', crm);
                    this.loadSolde();
                    this.$swal(
                        'Enregistrer !',
                        'Raport supprimer avec succès',
                        'success'
                    );

                } 
                catch (error) {
                    this.$swal(
                        'Erreur !',
                        "Echec de Suppression, merci de vérifier votre rapport",
                        'error'
                    );
                }
                NProgress.done();
            },

            clearForm(){
                this.form.id = null,
                this.form.date = '',
                this.form.enc_vte = 0,
                this.form.enc_autre = 0,
                this.form.enc_cred_m1 = 0,
                this.form.vte_cred = 0,
                this.form.retour = 0,
                this.form.depense  = 0,
                this.form.depense_all = '',
                this.form.description = '',
                this.form.observ = '',
                this.form.week_number = 0,
                this.form.month_number = 0,
                this.form.month_name = '',
                this.form.is_validate = 0,
                this.form.compte = []
            },

        },

        created(){
            this.formatSite();
            this.setCurentMonth();
            this.loadSolde();
        },

        mounted() {
           console.log(this.permissions);
        },
        destroyed() {
            this.loadingInstance.destroy();
        },

        watch: {
            tableData : function() {
                this.tableData = this.tableData;
            },

        },

    }
</script>

<style>
    .table-body-cell-caisse {
        background: #b8d8eb !important;
        color: #000000 !important;
    }

        .table-body-cell-date {
        background: #cccdcd !important;
        color: #000000 !important;
    }

    .table-body-cell-class2 {
        background: orange !important;
        color: #fff !important;
    }
</style>